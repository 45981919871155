@font-face {
  font-family: 'fira_sans';
  src: url('/fonts/firasans-regular.woff2') format('woff2'),
       url('/fonts/firasans-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'fira_sans';
  src: url('/fonts/firasans-semibold.woff2') format('woff2'),
       url('/fonts/firasans-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'fira_sans';
  src: url('/fonts/firasans-bold.woff2') format('woff2'),
       url('/fonts/firasans-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'fira_sans-bold';
  src: url('/fonts/firasans-bold.woff2') format('woff2'),
       url('/fonts/firasans-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'fira_sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  /* color: #d4ed72;
    color: #6ab05e;
    color: #4c90df;
    color: #162b48;
    color: #b7d8ff;
    color: #f0f1ff; */
}

a {
  text-decoration: none;
  color: #4c90df;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

label {
  margin-top: 10px;
  margin-bottom: 4px;
}

input, select, textarea {
  display: block;
  width: 100%;
  padding: 0 12px;
  height: 40px;
  color: #1E2036;
  line-height: 40px;
  margin: 4px 0 10px;
  border-radius: 4px 4px 0 0;
  border: 0;
  font-size: .9rem;
  outline: none;
  border-bottom: 1px solid #D4ED71;
  background: rgba(40, 131, 239, .05);
}

select {
  padding-right: 34px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right 16px top 50%, 0 0;
  background-size: .65em auto, 100%;
}
